<template>
	<div class="cropper-component">
		<!-- 展示内容部分 -->
		<div class="show_box">
			<!-- 展示选中图片 -->
			<div class="cropper" :style="{width:setWidth /2 +'px',height:setHeight/2  + 'px'}">
				<vue-cropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType"
					:info="option.info" :full="option.full" :canMove="option.canMove" :canMoveBox="option.canMoveBox"
					:original="option.original" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth"
					:autoCropHeight="option.autoCropHeight" :enlarge="option.enlarge" :fixedBox="option.fixedBox"
					@realTime="realTime" @imgLoad="imgLoad" :loading="loading"></vue-cropper>
			</div>
		</div>
		
		<div v-loading="is_spin" class="show_box">
			  ...
		 </div>
		<!-- 工具箱部分 -->
		<div class="btn-box">
			<div class="scope-btn">
				<el-button v-if="type===3" type="primary" plain @click="jingxiu()">精修</el-button>
				<!-- <label class="btn" for="uploads">
					替换2
				</label>
				<input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
					@change="uploadImg($event,'blob', 1)"> -->
				<label class="btn-upload">
					<el-upload
					  :show-file-list="false"
					  :action="fileUrl" 
					  :headers="header"
					  :data = "{uid:uid,type:type}"
					  :before-upload="handleBeforeUpload"
					  :on-success="handleSuccess">
					  <el-button><i class="el-icon-connection">替换</i></el-button>
					</el-upload>
				</label>
				<input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
					@change="uploadImg($event,'blob', 1)">
				<el-button plain icon="el-icon-refresh-left" @click="rotateLeft">左旋</el-button>
				<el-button plain icon="el-icon-refresh-right" @click="rotateRight">右旋</el-button>
				<el-button plain icon="el-icon-rank" style="margin-left: 10px;" @click="changCanMoveBox()">拖动</el-button>
				<el-button plain icon="el-icon-success" style="margin-left: 10px;" @click="finish('blob')">保存</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		VueCropper
	} from 'vue-cropper'
	import {
		uploadImageEdit,
		jingxiuImg
	} from '@/api/visa/order';
	import config from '@/config';
	export default {
		name: 'vueCropperImg',
		components: {
			VueCropper
		},
		props: {
			setWidth: {
				type: Number,
				default: 600
			},
			setHeight: {
				type: Number,
				default: 600
			},
		},
		data() {
			return {
				fileUrl: '',
				version: '',
				header: {},
				//剪切图片上传
				loading: false,
				is_spin: false,
				idx: 0,
				index: 0,
				uid : 0,
				type : 0,
				crap: false,
				previews: {},
				option: {
					img: '', // 裁剪图片的地址
					info: false, // 裁剪框的大小信息
					outputSize: 1, // 剪切后的图片质量（0.1-1）
					outputType: 'jpeg', // 裁剪生成的图片的格式
					canMove: true, // 上传图片是否可以移动
					original: false, // 上传图片是否显示原始宽高
					canMoveBox: false, // 截图框能否拖动
					autoCrop: false, // 是否默认生成截图框
					autoCropWidth: this.setWidth,
					autoCropHeight: this.setHeight,
					fixedBox: false, // 截图框固定大小
					fixed: false, //是否开启截图框宽高固定比例
					enlarge: 3, //图片根据截图框输出比例倍数
					fillColor:'#ffffff',//导出时背景颜色填充
				},
				fileName: '', // 本机文件地址
				downImg: '#',
				imgFile: '',
				uploadImgRelaPath: '', // 上传后的图片的地址（不带服务器域名）
			}
		},
		mounted() {
			this.fileUrl = `${config.baseURL}/platformapi/upload/upload_image_edit`
			this.version = config.version
			this.header = {
				token: this.$store.getters.token,
				version: this.version
			}
		},
		methods: {
			cropImage() {
			  if (this.cropper) {
				const canvas = this.cropper.getCroppedCanvas();
				this.croppedImageUrl = canvas.toDataURL('image/png');
			  }
			},
			changCanMoveBox(){
				if(this.option.canMoveBox){
					this.option.canMoveBox = false;
				}else{
					this.option.canMoveBox = true;
				}
			},
			// 将网络图片转换成base64格式
			transBase64FromImage(image) {
				let canvas = document.createElement('canvas')
				canvas.width = image.width
				canvas.height = image.height
				let ctx = canvas.getContext('2d')
				ctx.drawImage(image, 0, 0, image.width, image.height)
				// 可选其他值 image/jpeg
				return canvas.toDataURL('image/jpeg')
			},
			// 设置需要展示的图片  base64
			setAvatarBase64(src, callback) {
				let _this = this
				let image = new Image()
				// 处理缓存
				image.src = src + '?v=' + Math.random()
				// 支持跨域图片
				image.crossOrigin = '*'
				image.onload = function() {
					let base64 = _this.transBase64FromImage(image)
					_this.option.img = base64;
					callback && callback(base64)
				}
			},
			
			//替换图片
			tihuan(idx,img,index,uid,type,base=false) {
				this.is_spin = true;
				// if (base) {
				// 	this.option.img = base
				// } else {
					this.option.img = img
					// this.setAvatarBase64(img);
				// }
				this.idx = idx;
				this.index = index;
				this.uid = uid;
				this.type = type;
				this.option.autoCrop = true;
				if(this.type === 3){
					this.option.autoCropWidth = 235
					this.option.autoCropHeight = 350
				}else{
					this.option.autoCropWidth = this.setWidth
					this.option.autoCropHeight = this.setHeight
				}
			},
			// 放大/缩小
			changeScale(num) {
				num = num || 1;
				this.$refs.cropper.changeScale(num);
			},
			// 坐旋转
			rotateLeft() {
				this.$refs.cropper.rotateLeft();
			},
			// 右旋转
			rotateRight() {
				this.$refs.cropper.rotateRight();
			},
			// 上传图片（点击上传按钮）
			finish(type) {
				this.is_spin = true;
				// 输出
				if (type === 'blob') {
					this.$refs.cropper.getCropBlob((data) => {
						const time = new Date().getTime();
						const fileName = time + "_DX" + '.jpg';
						let file = new window.File([data], fileName, {
							uid: this.uid,
						});
						const datas = new FormData()
						datas.append('file', file)
						datas.append('idx', this.idx)
						datas.append('uid', this.uid)
						datas.append('index', this.index)
						datas.append('type', this.type)
						console.log(datas,'datas')
						uploadImageEdit(datas).then(res => {
							// this.option.img = window.URL.createObjectURL(res);
							this.is_spin = false;
							let datas = {
								url:res.url,
								src:res.src,
								base:res.base,
								idx:this.idx,
								uid:this.uid,
								index:this.index,
								type:this.type,
							}
							this.$emit('postFile', datas);
							return this.$message({
							  message: '裁剪成功',
							  type: 'success'
							});
						}).catch(err => {
							this.is_spin = false;
							this.$message.error('裁剪失败请重新操作')
						})
					})
				} else {
					let formData = new FormData();
					this.$refs.cropper.getCropData((data) => {
						formData.append('images', data);
						this.$emit('postFile', formData);
					})
					this.is_spin = false;
				}
			},
			
			handleBeforeUpload(){
				this.is_spin = true;
			},
			
			handleSuccess(res, file){
				this.is_spin = false;
				if(file.response.code){
					this.img = file.response.data.url
					let datas = {
						url:file.response.data.url,
						idx:this.idx,
						uid:this.uid,
						index:this.index,
						type:this.type,
						up:file.response.data.up,
						// base:file.response.data.base,
					}
					this.$emit('postFile', datas);
					return this.$message.success('替换成功')
				}else{
					return this.$message.error('上传失败')
				}
			},
			
			jingxiu(){
				this.is_spin = true;
				const datas = new FormData()
				datas.append('idx', this.idx)
				datas.append('uid', this.uid)
				datas.append('index', this.index)
				datas.append('type', this.type)
				// 输出
				jingxiuImg(datas).then(res => {
					this.$emit('postFile', res);
					this.is_spin = false;
					return this.$message({
					  message: '精修成功',
					  type: 'success'
					});
				}).catch(err => {
					this.is_spin = false;
					return this.$message({
					  message: '精修失败',
					  type: 'error'
					});
				})
			},
			// 实时预览函数
			realTime(data) {
				this.previews = data;
				this.is_spin = false;
			},
			// 下载图片
			down(type) {
				var aLink = document.createElement('a');
				let timer = new Date().getTime()
				aLink.download = timer; //文件名
				if (type === 'blob') {
					// 获取截图的blob数据
					this.$refs.cropper.getCropBlob((data) => {
						this.downImg = window.URL.createObjectURL(data); //生成blob格式图片路径
						aLink.href = window.URL.createObjectURL(data);
						aLink.click();
					})
				} else {
					// 获取截图的base64 数据
					this.$refs.cropper.getCropData((data) => {
						this.downImg = data;
						aLink.href = data;
					})
				}
			},
			// 选择本地图片
			uploadImg(e, type, num) { //num代表第几个
				// 上传图片
				var file = e.target.files[0];
				this.fileName = file.name;
				let reader = new FileReader();
				reader.onload = (e) => {
					let data = ''; //生成图片地址
					if (typeof e.target.result === 'object') {
						if (type == 'blob') {
							// 把Array Buffer转化为blob 如果是base64不需要
							data = window.URL.createObjectURL(new Blob([e.target.result]));
						}
					} else {
						data = e.target.result;
					}
					if (num === 1) {
						this.option.img = data;
					}
				}
				reader.readAsDataURL(file);
				/* if (type == 'blob') {
					// 转化为blob
					reader.readAsArrayBuffer(file);
				} else {
					// 转化为base64
					reader.readAsDataURL(file);
				} */
			},
			//图片加载的回调 imgLoad 返回结果success, error
			imgLoad(msg) {

			},
			//刷新-清除截图-目前尚未用到
			refeshImg(type) {
				if (type == 'start') {
					this.$refs.cropper.startCrop() //开始截图
				} else if (type == 'end') {
					this.$refs.cropper.stopCrop() //停止截图
				} else if (type == 'clear') {
					this.$refs.cropper.clearCrop() //清除截图
				}
			},
			// 根据文件名后缀区分 文件类型
			/*
			 * @param: fileName - 文件名称
			 * @param: 数据返回 1) 无后缀匹配 - false
			 * @param: 数据返回 2) 匹配图片 - image
			 * @param: 数据返回 3) 匹配 txt - txt
			 * @param: 数据返回 4) 匹配 excel - excel
			 * @param: 数据返回 5) 匹配 word - word
			 * @param: 数据返回 6) 匹配 pdf - pdf
			 * @param: 数据返回 7) 匹配 ppt - ppt
			 * @param: 数据返回 8) 匹配 视频 - video
			 * @param: 数据返回 9) 匹配 音频 - radio
			 * @param: 数据返回 10) 其他匹配项 - other
			 */
			matchType(fileName) {
				// 后缀获取
				var suffix = '';
				// 获取类型结果
				var result = '';
				try {
					var flieArr = fileName.split('.');
					suffix = flieArr[flieArr.length - 1];
				} catch (err) {
					suffix = '';
				}
				// fileName无后缀返回 false
				if (!suffix) {
					result = false;
					return result;
				}
				// 图片格式
				var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
				// 进行图片匹配
				result = imglist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'image';
					return result;
				};
				// 匹配txt
				var txtlist = ['txt'];
				result = txtlist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'txt';
					return result;
				};
				// 匹配 excel
				var excelist = ['xls', 'xlsx'];
				result = excelist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'excel';
					return result;
				};
				// 匹配 word
				var wordlist = ['doc', 'docx'];
				result = wordlist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'word';
					return result;
				};
				// 匹配 pdf
				var pdflist = ['pdf'];
				result = pdflist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'pdf';
					return result;
				};
				// 匹配 ppt
				var pptlist = ['ppt'];
				result = pptlist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'ppt';
					return result;
				};
				// 匹配 视频
				var videolist = ['mp4', 'm2v', 'mkv'];
				result = videolist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'video';
					return result;
				};
				// 匹配 音频
				var radiolist = ['mp3', 'wav', 'wmv'];
				result = radiolist.some(function(item) {
					return item == suffix;
				});
				if (result) {
					result = 'radio';
					return result;
				}
				// 其他 文件类型
				result = 'other';
				return result;
			},
		}
	}
</script>
<style lang="scss" scoped>
	.btn-upload{
		outline: none;
		display: inline-block;
		line-height: 1;
		white-space: nowrap;
		cursor: pointer;
		-webkit-appearance: none;
		text-align: center;
		box-sizing: border-box;
		outline: 0;
		transition: .1s;
		// font-weight: 500;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 3px;
		// margin-right: 10px;
		// height: 32px;
	}
	.cropper-component {
		width: 100%;
		// height: 500px;
		margin: 0 auto;
		position: relative;

		//工具箱部分
		.btn-box {
			margin: 20px 0;

			.btn {
				outline: none;
				display: inline-block;
				line-height: 1;
				white-space: nowrap;
				cursor: pointer;
				-webkit-appearance: none;
				text-align: center;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				outline: 0;
				-webkit-transition: .1s;
				transition: .1s;
				font-weight: 500;
				padding: 8px 15px;
				font-size: 12px;
				border-radius: 3px;
				color: #fff;
				background-color: #409EFF;
				border-color: #409EFF;
				margin-right: 10px;
				height: 32px;
			}
		}

		//展示内容部分
		.show_box {
			display: flex;

			// 展示选中图片
			// .cropper {
			//     width: 500px;
			//     height: 500px;
			// }
			// 展示缩略图
			.preview-box {
				margin-left: 500px;
				top: 60px;
				left: 10px;

				.preview {
					width: 400px;
					height: 400px;
					// border-radius: 50%;//这里预览是否需要变成圆的
					border: 1px solid #ccc;
					background-color: #ccc;
					margin: 5px;
					overflow: hidden;
				}
			}
		}

	}
</style>
